import clsx from "clsx"
import { ENFieldNote } from "en-react/dist/src/components/FieldNote"
import { ENProgress } from "en-react/dist/src/components/Progress"
import { useCallback, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { components, DropdownIndicatorProps, LoadingIndicatorProps, NoticeProps, PlaceholderProps } from "react-select"
import { AsyncPaginate } from "react-select-async-paginate"
import ZtnaIcon from "src/shared/components/Icons"
import { RootState } from "src/store"
import theme from "src/theme"
import { ENPaginatedSelectType } from "../FormComponents.types"
import { useENPaginatedSelectStyles } from "./ENPaginatedSelect.styles"

const SelectBorder = `0.5px solid ${theme.color.border.default}`
const SelectBorderFocused = `2px solid ${theme.color.border.accentDefault}`
const SelectBorderError = `1px solid ${theme.color.border.dangerDefault}`
const DisabledBorder = `0.5px solid #7e7f89`
const HoverBorder = `0.5px solid ${theme.color.content.default}`

const ENPaginatedSelect: React.FC<ENPaginatedSelectType> = ({
  id,
  isSearchable = false,
  value = "",
  error,
  onChange,
  placeholder = "Select",
  disabled = false,
  optionValue = "value",
  optionLabel = "label",
  isLoading = false,
  isClearable = false,
  customOption = null,
  loadOptions,
  fieldNote,
  label,
  defaultAdditional,
  iconName,
  onIconClick,
  objectCreationFunction,
  objectName,
}): JSX.Element => {
  const classes = useENPaginatedSelectStyles()
  const selectRef = useRef(null)

  const [menuIsOpen, setMenuIsOpen] = useState(false)

  const openedModals = useSelector((state: RootState) => state.ui.openedModals)
  const isModalOpen = Object.keys(openedModals).length > 0

  const DropdownIndicator = useCallback((dropDownProps: DropdownIndicatorProps) => {
    return (
      <components.DropdownIndicator {...dropDownProps}>
        <ZtnaIcon
          name="enChevronDown"
          style={
            dropDownProps.isDisabled
              ? { color: "#7e7f89" }
              : dropDownProps.isFocused
              ? { color: theme.color.content.default }
              : {}
          }
          size="lg"
        />
      </components.DropdownIndicator>
    )
  }, [])

  const NoOptionsMessage = (props: NoticeProps) => {
    return <components.NoOptionsMessage {...props}>{props.children}</components.NoOptionsMessage>
  }

  const LoadingMessage = (props: NoticeProps) => {
    return <components.NoOptionsMessage {...props}>{props.children}</components.NoOptionsMessage>
  }

  const LoadingIndicator = (props: LoadingIndicatorProps) => {
    return (
      <div className={classes.loaderAnimation}>
        <ENProgress currentProgress={100} endProgress={85} duration={1} isCircle />
      </div>
    )
  }

  const Placeholder = (props: PlaceholderProps) => {
    const isFocused = props.isFocused

    return (
      <components.Placeholder {...props}>
        <div>{isFocused ? placeholder : label}</div>
      </components.Placeholder>
    )
  }

  const showLabel = menuIsOpen || !!value

  return (
    <div className={clsx({ [classes.selectContainer]: disabled })} style={{ position: "relative" }}>
      <AsyncPaginate
        selectRef={selectRef}
        onMenuOpen={() => {
          setMenuIsOpen(true)
        }}
        onMenuClose={() => setMenuIsOpen(false)}
        menuIsOpen={menuIsOpen}
        classNamePrefix="react-select"
        isDisabled={disabled}
        isLoading={isLoading}
        isSearchable={isSearchable}
        isClearable={isClearable}
        value={
          (optionLabel || optionValue) && value ? { label: value?.[optionLabel], value: value?.[optionValue] } : value
        }
        placeholder={placeholder}
        loadOptions={loadOptions}
        loadOptionsOnMenuOpen={false}
        backspaceRemovesValue={false}
        onChange={onChange}
        components={{
          DropdownIndicator,
          IndicatorSeparator: () => null,
          LoadingIndicator,
          Placeholder,
          LoadingMessage,
          NoOptionsMessage,
        }}
        styles={{
          input: (base) => ({
            ...base,
            caretColor: "white",
            color: "white",
            paddingLeft: 10,
            fontFamily: "var(--en-font-family-primary)",
            fontWeight: "var(--en-font-weight-regular)",
            lineHeight: "var(--en-line-height-2)",
            fontSize: "var(--en-font-size-16)",
            letterSpacing: "var(--en-letter-spacing-0)",
            width: 80,
          }),
          placeholder: (base, state) => ({
            ...base,
            paddingLeft: 10,
          }),
          control: (provided, state) => ({
            ...provided,
            borderRadius: 4,
            boxShadow: "none",
            border: !state.isDisabled
              ? !!error
                ? SelectBorderError
                : state.isFocused
                ? SelectBorderFocused
                : SelectBorder
              : DisabledBorder,
            "&:hover": {
              border: !state.isDisabled
                ? !!error
                  ? SelectBorderError
                  : state.isFocused
                  ? SelectBorderFocused
                  : HoverBorder
                : DisabledBorder,
            },
            backgroundColor: "",
            height: 48,
            "&::before": showLabel
              ? {
                  content: `${'"' + label + '"'}`,
                  position: "absolute",
                  bottom: 33,
                  left: 10,
                  backgroundColor: theme.color.background.surfaceElevation1,
                  color: state.isFocused ? theme.color.content.default : theme.color.content.secondary,
                  fontSize: 12,
                  padding: "0px 5px",
                }
              : undefined,
          }),
          noOptionsMessage: (base) => ({
            ...base,
            marginTop: -5,
          }),
          loadingMessage: () => ({
            visibility: "hidden",
          }),
          singleValue: (base, state) => ({
            ...base,
            color: state.isDisabled ? "#7c7e88" : theme.color.content.default,
            fontFamily: "var(--en-font-family-primary)",
            fontWeight: "var(--en-font-weight-regular)",
            lineHeight: "var(--en-line-height-2)",
            fontSize: "var(--en-font-size-16)",
            letterSpacing: "var(--en-letter-spacing-0)",
            paddingLeft: 10,
            "&:hover": {
              color: state.isDisabled ? "#7c7e88" : theme.color.content.default,
            },
          }),
          clearIndicator: (base) => ({
            ...base,
            "&:hover": {
              color: "white",
            },
          }),
          dropdownIndicator: (base, state) => ({
            ...base,
            color: state.isDisabled
              ? state.isFocused
                ? theme.color.background.inverseDefault
                : theme.color.content.secondary
              : theme.color.border.default,
            transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : "rotate(0deg)",
            transition: "transform var(--en-anim-fade-quick) var(--en-anim-ease)",
            transformOrigin: "center center",
            "&:hover": {
              color: theme.color.background.inverseDefault,
            },
          }),
          menu: (base, state) => {
            const dropdownItems = (objectName ? 1 : 0) + state.options.length

            return {
              ...base,
              padding: "var(--en-dropdown-panel-body-padding, calc(var(--size-base-unit)* 0.5) 0)",
              border: "1px solid var(--en-theme-color-border-divider)",
              boxShadow: "var(--en-theme-box-shadow-lg)",
              borderStartStartRadius:
                "var(--en-dropdown-panel-border-block-start-left-radius, var(--en-theme-border-radius))",
              borderEndEndRadius:
                "var(--en-dropdown-panel-border-block-end-right-radius, var(--en-theme-border-radius))",
              borderEndStartRadius:
                "var(--en-dropdown-panel-border-block-end-left-radius, var(--en-theme-border-radius))",
              height:
                dropdownItems > 0
                  ? dropdownItems > 4
                    ? `calc(var(--size-base-unit) * ${isModalOpen ? "16" : "20"})`
                    : dropdownItems * 32 + 10 // 32 is height per option, 10 is additional offset
                  : "100%",
              backgroundColor: theme.color.background.surfaceElevation1,
              zIndex: theme.zIndex.dropDown,
            }
          },
          option: (base) => ({
            ...base,
            padding: 0,
            border: "none",
            margin: 0,
            // width: "100%",
            zIndex: theme.zIndex.dropDown,
            // backgroundColor: "transparent",
            // "&:active": {
            //   backgroundColor: "transparent",
            // },
            // EN ListItem Styles
            lineStyle: "none",
            marginBlockEnd: 1,
            fontFamily: "var(--en-font-family-primary)",
            fontWeight: "var(--en-font-weight-regular)",
            lineHeight: "var(--en-line-height-1)",
            fontSize: "var(--en-font-size-14)",
            letterSpacing: "var(--en-letter-spacing-0)",
            width: "var(--en-list-item-link-width, calc(100% - calc(var(--size-base-unit)* 0.5)))",
            marginInline:
              "var(--en-list-item-link-marign-inline-start, calc(var(--size-base-unit)* 0.25)) var(--en-list-item-link-marign-inline-start, calc(var(--size-base-unit)* 0.25))",
            display: "flex",
            justifyContent: "space-between",
            textDecoration: "none",
            paddingBlock:
              "var(--en-list-item-link-padding-block-start, calc(var(--size-base-unit)* 0.75)) var(--en-list-item-link-padding-block-end, calc(var(--size-base-unit)* 0.75))",
            paddingInline:
              "var(--en-list-item-link-padding-inline-start, calc(var(--size-base-unit)* 1)) var(--en-list-item-link-padding-inline-end, calc(var(--size-base-unit)* 1))",
            color: "inherit",
            transition: "background-color var(--en-anim-fade-quick) var(--en-anim-ease)",
            backgroundColor: "rgba(0,0,0,0)",
            cursor: "pointer",
            textAlign: "start",
            "&:hover": {
              backgroundColor:
                "var(--en-list-item-link-hover-background, var(--en-theme-color-background-surface-elevation-2))",
            },
          }),
          menuList: (base) => ({
            ...base,
            width: "100%",
            paddingTop: 0,
            position: "absolute",
            zIndex: theme.zIndex.dropDown,
            height: `calc(var(--size-base-unit) * ${objectName ? "15" : "19"})`,
          }),
          menuPortal: (base) => ({
            ...base,
            position: "absolute",
          }),
        }}
        debounceTimeout={500}
        menuShouldScrollIntoView={true}
        defaultAdditional={defaultAdditional}
      />
      {fieldNote && <ENFieldNote>{fieldNote}</ENFieldNote>}
      {!!error && <ENFieldNote isError={!!error}>{error}</ENFieldNote>}
    </div>
  )
}

export default ENPaginatedSelect
